.body {
	word-break: break-word;
	color: var(--token-color-foreground-faint);

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	& h2,
	& h3,
	& h4,
	& h5,
	& h6 {
		position: relative;
	}

	& strong {
		color: var(--token-color-foreground-strong);
	}

	& hr {
		margin: var(--hdsplus-spacing-08) 0;
	}

	& > h2 {
		margin: var(--hdsplus-spacing-09) 0;
	}

	& > h3 {
		margin: var(--hdsplus-spacing-07) 0;
	}

	& > h4,
	& > h5,
	& > h6,
	& > p {
		margin: var(--hdsplus-spacing-04) 0;
	}
}

.bodyElement {
	max-width: 680px;
}

.blockWrapper {
	& > *:last-child {
		margin-bottom: 0;
	}

	& > *:first-child {
		margin-top: 0;
	}
}

/* Makes sizes responsive */
.size100,
.size200 {
	@media (max-width: 1119px) {
		font-family: var(--hdsplus-typography-display-expressive-100-font-family);
		font-size: var(--hdsplus-typography-display-expressive-100-font-size);
		line-height: var(--hdsplus-typography-display-expressive-100-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-100-letter-spacing
		);
	}
}

.size300 {
	@media (--small) {
		font-family: var(--hdsplus-typography-display-expressive-100-font-family);
		font-size: var(--hdsplus-typography-display-expressive-100-font-size);
		line-height: var(--hdsplus-typography-display-expressive-100-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-100-letter-spacing
		);
	}

	@media (--medium) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-200-letter-spacing
		);
	}
}

.size400 {
	@media (--small) {
		font-family: var(--hdsplus-typography-display-expressive-200-font-family);
		font-size: var(--hdsplus-typography-display-expressive-200-font-size);
		line-height: var(--hdsplus-typography-display-expressive-200-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-200-letter-spacing
		);
	}

	@media (--medium) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}
}

.size500 {
	@media (--small) {
		font-family: var(--hdsplus-typography-display-expressive-300-font-family);
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}

	@media (--medium) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}
}

.size600 {
	@media (--small) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}

	@media (--medium) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family);
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-500-letter-spacing
		);
	}
}

.blockQuote {
	margin: var(--hdsplus-spacing-10) 0;
	padding-left: 30px;
	position: relative;

	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		height: 100%;
		width: 10px;
		background: var(--token-color-palette-neutral-200);
		border-radius: 4px;
	}
}

.quote {
	font-style: italic;
	margin-bottom: var(--hdsplus-spacing-05);
}
